
.sidebar {
    width: 35%;
}
.content {
    width: 90%;
}

.steps {
    height: fit-content;
}

.docNotification,
.refer {
    border-color: #2ca721;
}

.icons,
.docNotification .icons,
.refer .yes,
.address-note,
.camera-icon,
.add-images-btn,
.guidelines-image-btn,
.cuisine-list,
.not-selected-cuisine,
.show-more-btn,
.dates,
.form-checkbox,
.settings,
.content-head {
    color: #2ca721;
}

.upload-images-border,
.cuisine-list,
.dates
 {
    border-color: #2ca721;
}

.okay-btn,
.verify-btn,
.resend-btn,
.selected-cuisine,
.form-checkbox {
    background-color: #2ca721;
}

.address-note {
    background-color: #98eb90;
}

.selected-cuisine {
    color: white;
}


.device:hover{
    border: solid #2ca721 2px;
}

.regis{
    height: 600px;
}
.restaurant{
    width: 400px;
}