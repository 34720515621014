.home {
    /* Background image */
    /* background: url('../images/bg.webp') no-repeat center center fixed; */
    background-size: cover;
    min-height: 100vh;
}

.logo {
    height: 8vh;
    width: auto;
}

.register-btn {
    background-color: #2ca721;
}

.register-btn:hover {
    background-color: hsl(115, 70%, 30%);
}

.phoneNum,
.doc-icon,
.doc-content {
    color: #2ca721
}
